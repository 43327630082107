import React from 'react';

import messages from '../../data/messages/es';
import Layout from '../layout';


export default (props) => (
  <Layout
    {...props}
    i18nMessages={messages}
  />
);