import * as React from "react"
import Layout from "../components/layout/es"
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image"
import { FormattedMessage } from "react-intl";

// markup
const NotFoundPage = (props) => {

  return (
    <Layout transparent location={props.location} pageId="404" metaTitle="Página no encontrada | Crequs">
      <section className="error-page">
        <div className="container">
          <div className="error-content">
          <StaticImage
                src="../images/illustracion/404.svg"
                alt="Crequs"
                width={500}
                loading="eager"
                backgroundColor="transparent"
                placeholder="tracedSVG"
            />
            <h3><FormattedMessage id="404Title" defaultMessage="¡Ups! Página no encontrada"/></h3>
            <p><FormattedMessage id="404Subtitle" defaultMessage="Parece que la página que estás buscando no existe" /> &hellip;</p>
            <Link className="btn-default box-shadow-none" style={{textTransform:'none'}} to="/"><FormattedMessage id="404Button" defaultMessage="Volver a la portada" /></Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage


